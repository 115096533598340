<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <b-card class="mb-0">
        <b-link class="brand-logo">

          <h2 class="brand-text text-primary ml-1">
            IntellySCALE
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t("Forgot Password?") }} 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          {{
            $t(
                "Enter your email and we'll send you instructions to reset your password"
            )
          }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
                :label="$t('Email')"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Email')"
                  rules="required|email"
              >
                <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


            <b-button
                variant="success"
                block
                type="submit"
            >
              {{ $t("Send reset link") }}
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> {{ $t("Back to login") }}
          </b-link>
        </b-card-text>

      </b-card>

    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  data() {
    return {
      userEmail: '',
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate()
          .then((success) => {
            if (success) {
              this.$http
                  .post('/forgot-password', {
                    email: this.userEmail,
                  })
                  .then(res => {
                    this.$router.push(
                        {
                          name: 'auth-login-with-email',
                          params: {
                            email: this.userEmail,
                          }
                        },
                        () => setTimeout(() => {
                          this.$bvToast.toast(this.$i18n.t('We have sent you an email with further instructions'), {
                            title: this.$i18n.t('Success'),
                            variant: 'success',
                            toaster: 'b-toaster-bottom-right'
                          })
                        })
                    )
                  })
                  .catch(error => {
                    this.$bvToast.toast(this.$i18n.t('It looks like we don\'t have this email in the system or you have already made a password reset request recently'),
                        {
                          title: this.$i18n.t('Error'),
                          variant: 'danger',
                          toaster: 'b-toaster-bottom-right'
                        })
                  })

            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
